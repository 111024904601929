import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TrbmFlexNoFooter from "src/components/trbmflexNoFooter"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import BtfTwoCol from "src/components/btfTwoCol"

const ReadMoreData = import("./data/data.json");
const BelowData = import("./data/belowdata.json");

export default function readmore() {

  let css;


  return(
    <HomepageLayout>
    <Helmet>
    <style type="text/css">{`
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

    body {
      background-image: url(/img/lpbg-sm.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat", sans-serif;
    }

    trbmflexnofooter-module--trbmnofooter {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    * {
    font-family: "Montserrat", sans-serif;
    }

    #ctadisclosuresearch-module--ctadisclaimersearch {
      font-weight: normal;
      max-width: 1000px;
      margin: 0 auto;
    }


    .footerlinkssingle-module--footerWrap footer ul li,
    .footerlinkssingle-module--footerWrap footer ul li a {
        color: #333!important;
      }

    .footerlinkssingle-module--footerWrap {
      height: .5rem;
    }

    `}
    </style>
    <title>FabTab | Beautiful Backgrounds and the Latest Fashion News</title></Helmet>
    <section>

      <TrbmFlexNoFooter data={ReadMoreData}>
        <CtaDisclosureSearch data={ReadMoreData}></CtaDisclosureSearch>
      </TrbmFlexNoFooter>
      <BtfTwoCol data={BelowData}>
        <CtaDisclosureSearch data={BelowData}></CtaDisclosureSearch>
      </BtfTwoCol>
      </section>
    </HomepageLayout>
  )
}

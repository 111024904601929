import React from "react"
import BtfData from "./data/btfdata.json"
import Styles from "./css/btftwocol.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import FooterlinksSingle from "src/components/footerlinksSingle/footer.js"

class BtfTwoCol extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    });
  }

  render() {
    return (
      <div className = {Styles.btftwocol} style={{ display: this.state.display }}>
              <div className = {Styles.bottom}>

                <h2 className = {Styles.btfHeadline}>{this.state.data.btfHeadline}</h2>
                <div className = {Styles.twocolInner}>
                    <div className = {Styles.left}>
                        <div className = {Styles.bulletpoint}>
                            <div className = {Styles.bulletHeader}>{this.state.data.header1}</div>
                            <div className = {Styles.copy}>{this.state.data.descrip1}</div>
                        </div>
                        <div className = {Styles.bulletpoint}>
                            <div className = {Styles.bulletHeader}>{this.state.data.header2}</div>
                            <div className = {Styles.copy}>{this.state.data.descrip2}</div>
                        </div>
                        <div className = {Styles.bulletpoint}>
                            <div className = {Styles.bulletHeader}>{this.state.data.header3}</div>
                            <div className = {Styles.copy}>{this.state.data.descrip3}</div>
                        </div>
                    </div>

                    <div className = {Styles.right}>
                        <img src={this.state.data.productImg}/>
                    </div>
                </div>

                <button onClick = {() => window.triggerInstall()} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
                {this.props.children}
                
                </div>
                <FooterlinksSingle></FooterlinksSingle>
          </div>
    )
  }
}
export default BtfTwoCol
